import { Controller } from "@hotwired/stimulus";

// Show the file name in the sibling span of a file input. Depends on classes:
//   1. `file-name` div to display the currently attached file name
//   2. `field is-horizontal` being the outermost div
//
export default class extends Controller {
  static targets = ["input"]

  attached(event) {
    const input = event.currentTarget;
    // Show the file name in the sibling span
    // See: https://bulma.io/documentation/form/file/
    input.parentElement.querySelector('.file-name').textContent =
      Array.prototype.map.call(input.files, file => file.name).join(', ');
  }
}

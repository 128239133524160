import { Controller } from "@hotwired/stimulus";
import { tns } from 'tiny-slider/src/tiny-slider';

export default class extends Controller {
  static values = { options: Object }
  static targets = ["items"]

  connect() {
    this.slider = tns({ container: this.itemsTarget, ...this.optionsValue });
  }
  disconnect() {
    this.slider.destroy();
  }
}

import { Controller } from "@hotwired/stimulus";

// Bulma navbar controller
export default class extends Controller {
  static targets = [ "menu", "burger" ]
  toggleMenu() {
    this.menuTarget.classList.toggle('is-active');
    this.burgerTarget.classList.toggle('is-active');
  }
}

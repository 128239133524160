import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox';

// Glightbox options docs: https://github.com/biati-digital/glightbox
export default class extends Controller {
  static values = { options: Object }

  connect() {
    GLightbox({
      ...this.defaultOptions(),
      ...this.optionsValue,
    })
  }

  defaultOptions() {
    return { loop: true }
  }
}
